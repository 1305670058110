import React, { useState } from "react";
import { connect } from "react-redux";

import { Button } from "@bit/healthmug.admin.button";

import { LoginSetEmail, LoginSubmitUser, LoginOnFocus } from "~/actions/login-action";

import { ProjectUrl, RecaptchaClientKey } from "~/env";

function Login({ email, errors, loader, Login_Set_Email, Login_Submit_User_Login, Login_On_Focus }) {
	const [pwd, setPwd] = useState("");

	const onKeyPressLogin = (e) => {
		if (e.key === "Enter") onLogin();
	};

	const onLogin = () => {
		grecaptcha.ready(() => {
			grecaptcha.execute(RecaptchaClientKey, { action: "AdminApi_Login" }).then((token) => {
				Login_Submit_User_Login(pwd, token)
					.then((user_name) => {
						localStorage.setItem("user_name", user_name);
						window.location = `${ProjectUrl}/analytics/dashboard`;
					})
					.catch(() => { });
			});
		});
	};

	return (
		<div className="loginWrap">
			<div className="loginInnerBox">
				<div style={{ display: "flex" }}>
					<img src="/images/logo-only.png" style={{ width: "70px", height: "70px", marginRight: "15px" }} className="logo" alt="" />
					<div style={{ flex: 1, color: "#fff" }}>
						<span>Healthmug</span>
						<h2 style={{ margin: "0px", color: "#fff" }}>Marketing</h2>
					</div>
				</div>
				<div className={`${errors.email ? "hasError" : ""}`}>
					<label htmlFor="email">
						Email Id
						<input
							type="email"
							id="email"
							name="email"
							onChange={(e) => Login_Set_Email(e.target.value)}
							value={email}
							autoComplete="off"
							placeholder="Enter your email..."
							onFocus={(e) => Login_On_Focus(e.target.name)}
						/>
					</label>
					{errors.email && <div className="error">{errors.email}</div>}
				</div>
				<div className={`${errors.pwd ? "hasError" : ""}`}>
					<label htmlFor="pwd">
						Password
						<input
							type="password"
							name="pwd"
							id="pwd"
							onChange={(e) => setPwd(e.target.value)}
							placeholder="Enter password..."
							autoComplete="off"
							value={pwd}
							onFocus={(e) => Login_On_Focus(e.target.name)}
							onKeyPress={onKeyPressLogin}
						/>
					</label>
					{errors.pwd && <div className="error">{errors.pwd}</div>}
				</div>
				<Button onClick={onLogin} loader={loader} className="uk-button uk-button-secondary uk-width-1-1">
					Login
				</Button>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => ({
	email: state.email,
	loader: state.loader,
	errors: state.errors
});
const mapDispatchToProps = (dispatch) => ({
	Login_Set_Email: (value) => dispatch(LoginSetEmail(value)),
	Login_On_Focus: (key) => dispatch(LoginOnFocus(key)),
	Login_Submit_User_Login: (pwd, token) => dispatch(LoginSubmitUser(pwd, token))
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
